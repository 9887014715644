function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function vModel(self, dataObject, defaultValue) {
  dataObject.props.value = defaultValue;
  dataObject.on.input = function (val) {
    self.$emit('input', val);
  };
}
var componentChild = {};
/**
 * 将./slots中的文件挂载到对象componentChild上
 * 文件名为key，对应JSON配置中的__config__.tag
 * 文件内容为value，解析JSON配置中的__slot__
 */
var slotsFiles = require.context('./slots', true, /\.js$/);
var keys = slotsFiles.keys() || [];
keys.forEach(function (key) {
  var tag = key.replace(/^\.\/(.*)\.\w+$/, '$1');
  var value = slotsFiles(key).default;
  componentChild[tag] = value;
});
export default {
  render: function render(h) {
    var _this = this;
    var dataObject = {
      attrs: {},
      props: {},
      on: {},
      style: {}
    };
    var confClone = JSON.parse(JSON.stringify(this.conf));
    var children = [];
    var childObjs = componentChild[confClone.__config__.tag];
    if (childObjs) {
      Object.keys(childObjs).forEach(function (key) {
        var childFunc = childObjs[key];
        if (confClone.__slot__ && confClone.__slot__[key]) {
          children.push(childFunc(h, confClone, key));
        }
      });
    }
    Object.keys(confClone).forEach(function (key) {
      var val = confClone[key];
      if (key === '__vModel__') {
        vModel(_this, dataObject, confClone.__config__.defaultValue);
      } else if (dataObject[key]) {
        dataObject[key] = _objectSpread(_objectSpread({}, dataObject[key]), val);
      } else {
        dataObject.attrs[key] = val;
      }
    });
    delete dataObject.attrs.__config__;
    delete dataObject.attrs.__slot__;
    return h(this.conf.__config__.tag, dataObject, children);
  },
  props: ['conf']
};